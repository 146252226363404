import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LanguageTranslationModule } from './shared/modules/language-translation/language-translation.module'
import { FormsModule,ReactiveFormsModule }    from '@angular/forms';
// import { AngularFireModule } from 'angularfire2';
// import { AngularFirestoreModule, FirestoreSettingsToken } from 'angularfire2/firestore';
// import { AngularFireAuthModule } from 'angularfire2/auth';
// import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from '../environments/environment';
// import { AuthService } from './shared/services/auth.service';
// import { AngularFireAuthGuard } from '@angular/fire/auth-guard';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxSpinnerModule } from 'ngx-spinner';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './shared';
import { HomeComponent } from './home/home.component';
import { MyDatePickerModule } from 'mydatepicker';
import { DragulaModule } from 'ng2-dragula';


import { DataTablesModule } from 'angular-datatables';

// import { CatalogsComponent } from './catalogs/catalogs.component';

// import { CatalogsComponent } from './catalogs/catalogs.component';

@NgModule({

    declarations: [
      AppComponent,
      HomeComponent,
      // CatalogsComponent,
      // CatalogsComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        DataTablesModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        LanguageTranslationModule,
        MyDatePickerModule,
        DragulaModule.forRoot(),
        // AngularFireModule.initializeApp(environment.firebase),
        // AngularFireAuthModule,
        // AngularFirestoreModule,
        // AngularFireStorageModule,
        AppRoutingModule,
        NgxSpinnerModule,
        NgMultiSelectDropDownModule.forRoot()
    ],
    // providers: [AuthService],
    bootstrap: [AppComponent]
})
export class AppModule {}
