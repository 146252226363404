import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router,NavigationExtras } from '@angular/router';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
// import { AuthService } from '../shared/services/auth.service';
import { routerTransition } from '../router.animations';

import { ApiService }  from '../shared/services/api.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [routerTransition()]
})


export class HomeComponent implements OnInit {

  dataform: FormGroup;

  data:string;

  constructor(private dataService: ApiService, private formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router) {


    this.dataform = this.formBuilder.group({
      password: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]]
    });

  }

  ngOnInit() {



  }

  login(){


    let password = this.dataform.controls.password.value;
    let email = this.dataform.controls.email.value;


    if (this.dataform.controls.email.status==="INVALID") {
      alert('invalid')
    }


    if (this.dataform.invalid) {
        alert('fields')
    }


    var userPostData = {
        "email": email,
        "password": password
    };


    this.dataService.postDataFree(userPostData,'/users/login')
     .subscribe(
         data => this.data = data,//this.data = data,
         error => console.log("Error: ", error),
         () =>  this.next(this.data)
       );

  }

  next(d){
    // this.spinner.hide();

    // debugger

    if(d.status!="error"){


      localStorage.setItem('t',d.token)
      localStorage.setItem('name',d.name)
      this.router.navigate(['/dashboard'])


    }
    else{

      // this.notifier.show( {
      //   type: 'error',
      //   message: d.message
      // });

      return;

    }

  }






}
