import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';


@Injectable({ providedIn: 'root' })
export class ApiService {

  //private api_url = '/public'; // We need change this URL to point at the API
   //private api_url = '/api'; // We need change this URL to point at the API
   public api_url = environment.api;

  constructor(
    private http: HttpClient) { }


  getData(param_url:string): Observable<any> {

    const url = this.api_url + param_url;

    return this.http.get<any>(url,this.getHeaders()).pipe(
      catchError(this.handleError<any>(''))
    );
  }

  postData(params:any,param_url:string): Observable<any> {

    // debugger

    const url = this.api_url + param_url;

    return this.http.post<any>(url,JSON.stringify(params), this.getHeaders()).pipe(
      catchError(this.handleError<any>(''))
    );

    // return this.http.post<any>(url,JSON.stringify(params)).pipe(
    //   catchError(this.handleError<any>(''))
    // );
  }

  postDataFree(params:any,param_url:string): Observable<any> {

    const url = this.api_url + param_url;

    return this.http.post<any>(url,JSON.stringify(params)).pipe(
      catchError(this.handleError<any>(''))
    );
  }

  getHeaders(){
    const httpOptions = {
      headers: new HttpHeaders({ 'content-type': 'application/json','authorization': 'Bearer ' + localStorage.getItem('t')  })
    };

    return httpOptions;

  }

  postFile(fileToUpload: File): Observable<any> {

    

    const url = this.api_url + '/uploads/add';

    let formData = new FormData();
    formData.append('file', fileToUpload)

    // return this.http.post(url, formData,{ headers: {}});
    return this.http.post(url, formData,{ headers: {'enctype': 'multipart/form-data','Authorization': 'Bearer ' + localStorage.getItem('t')}});
  }

  postFiles(fileToUpload: FormData,id): Observable<any> {

    const url = this.api_url + '/uploads/multiple';

    // let formData = new FormData();
    // formData.append('files[]', fileToUpload)
    fileToUpload.append('id', id)

    return this.http.post(url, fileToUpload,{ headers: {'enctype': 'multipart/form-data','Authorization': 'Bearer ' + localStorage.getItem('t')}});
    // return this.http.post(url, fileToUpload,{ headers: {}});
  }


  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      //console.error(error);
      //alert(error.message);
      return of(error.error);



    };
  }

   private log(message: string) {
    //console.log('fetched templates')
  }

}
